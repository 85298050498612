


































































































































































































































































































import {
  CreateTitleObjectInput,
  CreateTitleObjectMutationVariables,
  CreateTitleObjectMutation,
  TitleObjectStateEnum,
  TitleObjectTypeEnum,
} from "@/generated/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CreateTitleObject } from "@/graphql/queries/TitleObject.graphql";
import { apolloClient } from "@/core/apollo";
import { required } from "@/core/validation";
import TimeInput from "@/components/widgets/commons/Inputs/TimeInput.vue";
import Form from "@/components/widgets/commons/Prompts/Form.vue";

export type TitleBlock = {
  type: string;
  name: string;
};

@Component({
  name: "TitleObjects",
  components: { Form, TimeInput },
  methods: {
    required,
  },
})
export default class TitleObjects extends Vue {
  @Prop(Boolean) EDIT_TITLE_OBJECT!: boolean;
  @Prop(String) streamID!: string;

  typesTitleObject: { value: TitleObjectTypeEnum; text: string }[] = [
    {
      value: TitleObjectTypeEnum.Notification,
      text: "Уведомления",
    },
    {
      value: TitleObjectTypeEnum.Poll,
      text: "Опрос",
    },
    {
      value: TitleObjectTypeEnum.Question,
      text: "Вопрос",
    },
    {
      value: TitleObjectTypeEnum.Presence,
      text: "Присутствие",
    },
    {
      value: TitleObjectTypeEnum.Welcome,
      text: "Приветствие",
    },
  ];

  selectedTypeTitleObject: string = TitleObjectTypeEnum.Notification;

  done: boolean = false;
  showTitled: boolean = false;
  invalidPoll: boolean = false;
  pollAnswers: Array<{ text: string; is_correct: boolean }> = [];
  errors: string = "";
  error: boolean = false;
  clearTitled: CreateTitleObjectInput = {
    title: "",
    text: "",
    type: TitleObjectTypeEnum["Notification"],
    stream_id: 0,
    state: TitleObjectStateEnum["Queued"],
    start: 0,
    duration: 1,
    is_modal: false,
  };
  notify: CreateTitleObjectInput = {
    title: "",
    text: "",
    type: TitleObjectTypeEnum["Notification"],
    stream_id: +this.streamID,
    state: TitleObjectStateEnum["Queued"],
    start: 0,
    duration: 0,
    is_modal: false,
  };
  question: CreateTitleObjectInput = {
    title: "",
    text: "",
    type: TitleObjectTypeEnum["Question"],
    stream_id: 0,
    state: TitleObjectStateEnum["Queued"],
    start: 0,
    duration: 1,
    is_modal: false,
    button_text: "",
  };
  poll: CreateTitleObjectInput = {
    title: "",
    type: TitleObjectTypeEnum["Poll"],
    stream_id: 0,
    state: TitleObjectStateEnum["Queued"],
    start: 0,
    duration: 1,
    is_modal: false,
  };
  presence: CreateTitleObjectInput = {
    title: "",
    text: "",
    type: TitleObjectTypeEnum["Presence"],
    stream_id: 0,
    state: TitleObjectStateEnum["Queued"],
    start: 0,
    duration: 1,
    is_modal: false,
  };
  welcome: CreateTitleObjectInput = {
    title: "",
    text: "",
    type: TitleObjectTypeEnum["Welcome"],
    stream_id: 0,
    state: TitleObjectStateEnum["Queued"],
    start: 0,
    duration: 1,
    is_modal: false,
  };

  addOption(): void {
    this.pollAnswers.push({ text: "", is_correct: false });
  }

  public $refs!: {
    notifyForm: HTMLFormElement;
    questionForm: HTMLFormElement;
    pollForm: HTMLFormElement;
    presenceForm: HTMLFormElement;
    welcomeForm: HTMLFormElement;
    [key: string]: any;
  };

  async createTitle(
    type: TitleObjectTypeEnum,
    formType: string
  ): Promise<void> {
    const isValidForm = await this.$refs[formType].validate();
    if (isValidForm) {
      let titleObject!: CreateTitleObjectInput;
      this.errors = "";
      this.error = false;
      switch (type) {
        case "NOTIFICATION":
          titleObject = {
            ...this.notify,
            ...{ start: +this.notify.start },
            ...{ duration: +this.notify.duration },
            ...{ stream_id: +this.streamID },
          };
          this.notify.duration = 0;
          this.notify.start = 0;
          break;
        case "QUESTION":
          titleObject = {
            ...this.question,
            ...{ start: +this.question.start },
            ...{ duration: +this.question.duration },
            ...{ stream_id: +this.streamID },
          };
          this.question.duration = 0;
          this.question.start = 0;
          break;
        case "POLL":
          if (!this.pollAnswers.length) {
            this.errors = "Введите варианты опроса";
            this.error = true;
            break;
          }
          titleObject = {
            ...this.poll,
            ...{ start: +this.poll.start },
            ...{ duration: +this.poll.duration },
            ...{
              pollAnswers: {
                create: this.pollAnswers,
              },
              ...{ stream_id: +this.streamID },
            },
          };
          this.pollAnswers = [];
          this.poll.duration = 0;
          this.poll.start = 0;
          break;
        case "PRESENCE":
          titleObject = {
            ...this.presence,
            ...{ start: +this.presence.start },
            ...{ duration: +this.presence.duration },
            ...{ stream_id: +this.streamID },
          };
          this.presence.duration = 0;
          this.presence.start = 0;
          break;
        case "WELCOME":
          titleObject = {
            ...this.welcome,
            ...{ start: +this.welcome.start },
            ...{ duration: +this.welcome.duration },
            ...{ stream_id: +this.streamID },
          };
          this.welcome.duration = 0;
          this.welcome.start = 0;
          break;
      }
      try {
        const { data } = await apolloClient.mutate<
          CreateTitleObjectMutation,
          CreateTitleObjectMutationVariables
        >({
          mutation: CreateTitleObject,
          variables: {
            input: titleObject,
          },
        });
        if (data) {
          this.$emit("addTitleObject", {
            ...titleObject,
            ...{ id: data.createTitleObject?.id },
          });
        }
        this.done = true;
      } catch (err: any) {
        this.errors = err;
        this.error = true;
      } finally {
        this.$refs[formType].reset();
        this.$refs[formType].resetValidation();
        // this.showTitled = false;
      }
    }
  }
}
