







































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TitleObject, TitleObjectStateEnum, Stream } from "@/generated/graphql";
import { DataTableHeader } from "vuetify";
import { TitleObjectType, TitleObjectState } from "@/core/static/dict";
import { mixins } from "vue-class-component";
import userRolesMixin from "@/mixins/userRoles";
import { DateTime } from "luxon";

@Component({
  name: "TitleObjectsTable",
  computed: {
    titleObjectStateEnums() {
      return TitleObjectStateEnum;
    },
  },
})
export default class TitleObjectsTable extends mixins(userRolesMixin, Vue) {
  @Prop({ default: [] }) titleObjects!: TitleObject[];
  @Prop({ required: true }) stream!: Stream;
  @Prop({ required: true, type: Number }) streamStarted!: number;
  @Prop({ type: Boolean, default: true }) titleObjectsLoading!: boolean;

  headers: Array<DataTableHeader> = [
    { text: "Состояние", value: "state" },
    { text: "Старт", value: "start" },
    { text: "Длительность", value: "duration" },
    { text: "Блок", value: "type" },
    { text: "Действие", value: "action" },
  ];
  TitleObjectState = TitleObjectState;
  TitleObjectType = TitleObjectType;
  enteredPage: boolean = true;
  timerStop: Record<string, ReturnType<typeof setTimeout>> = {};
  timerStart: Record<string, ReturnType<typeof setTimeout>> = {};

  mounted(): void {
    const stream = this.stream;
    if (!stream) return;
    if (!stream) return;
    if (stream.is_started && this.enteredPage) {
      /**
       * Получить разницу между now и стрим старт + title start + title duration
       **/
      const streamStart = DateTime.fromISO(stream.start);
      this.titleObjects.forEach(async (element: TitleObject) => {
        const startTitle = +streamStart
          .plus({ seconds: element.start })
          .diffNow("seconds")
          .seconds.toFixed(0);
        const endTitle = +streamStart
          .plus({ seconds: element.start + element.duration })
          .diffNow("seconds")
          .seconds.toFixed(0);
        /**
         * При изменении статуса на сервере обновится event, stream и сработает вотчер на стрим
         * Переводит в done, если done уже прошел
         **/

        if (endTitle <= 0 && element.state !== TitleObjectStateEnum["Done"]) {
          this.updateTitleStatus(element, TitleObjectStateEnum.Done);
        }
        /* Переводит в started, если started уже прошел */
        if (
          startTitle <= 0 &&
          endTitle > 0 &&
          element.state !== TitleObjectStateEnum["Started"] &&
          element.state !== TitleObjectStateEnum["Done"]
        ) {
          this.updateTitleStatus(element, TitleObjectStateEnum["Started"]);
        }
        /* Запустить счетчик для очереди при открытии страницы */
        /* Если еще не запустился, то поставить таймер на started и done */
        if (startTitle > 0) {
          this.delayStart(element, startTitle, element.duration);
        }
        /* Если еще запустился, то поставить таймер на done */
        if (startTitle <= 0 && endTitle >= 0) {
          this.delayDone(element, endTitle);
        }
      });
    }
  }
  beforeDestroy(): void {
    this.clearTimers();
  }
  @Watch("streamStarted", {
    deep: true,
    immediate: true,
  })
  onStreamStarted(val: number): void {
    if (val === 0) {
      this.clearTimers();
      return;
    }
    this.enteredPage = false;
    /* Запустить счетчик для очереди при запуске стрима */
    this.titleObjectQueued.forEach((element: TitleObject) => {
      this.delayStart(element, element.start, element.duration);
    });
    this.titleObjectStarted.forEach((element: TitleObject) => {
      this.delayDone(element, element.duration);
    });
  }
  clearTimers(): void {
    for (let key in this.timerStart) {
      clearTimeout(this.timerStart[key]);
    }
    for (let key in this.timerStop) {
      clearTimeout(this.timerStop[key]);
    }
    this.timerStop = {};
    this.timerStart = {};
  }
  private secondsTomiliseconds(duration: number) {
    return duration * 1000;
  }

  private delayStart(item: TitleObject, start: number, duration: number): void {
    clearTimeout(this.timerStart[item.id]);
    this.timerStart[item.id] = setTimeout(async () => {
      this.updateTitleStatus(item, TitleObjectStateEnum.Started);
      this.delayDone(item, duration);
    }, this.secondsTomiliseconds(start));
  }

  private delayDone(item: TitleObject, duration: number): void {
    clearTimeout(this.timerStop[item.id]);
    this.timerStop[item.id] = setTimeout(async () => {
      this.updateTitleStatus(item, TitleObjectStateEnum.Done);
    }, this.secondsTomiliseconds(duration));
  }

  private async run(item: TitleObject): Promise<void> {
    clearTimeout(this.timerStart[item.id]);
    clearTimeout(this.timerStop[item.id]);
    const startedDate = DateTime.fromISO(this.stream.start);
    const diff = parseInt(
      (startedDate.diffNow("seconds").seconds * -1).toFixed(0)
    );
    this.$emit("updateTitleStatus", {
      item: { ...item, ...{ start: diff } },
      status: TitleObjectStateEnum.Started,
    });
    const startTitleTime = diff + item.start;
    const sinceStartDuration = startTitleTime + item.duration;
    this.timerStop[item.id] = setTimeout(() => {
      this.$emit("updateTitleStatus", {
        item,
        status: TitleObjectStateEnum.Done,
      });
    }, sinceStartDuration * 1000);
  }

  public stop(item: TitleObject): void {
    clearTimeout(this.timerStop[item.id]);
    this.updateTitleStatus(item, TitleObjectStateEnum.Done);
  }
  public get titleObjectQueued(): TitleObject[] | [] {
    if (!this.titleObjects || !this.titleObjects.length) {
      return [];
    }
    return this.titleObjects.filter(
      (a: TitleObject) => a.state === TitleObjectStateEnum.Queued
    );
  }

  public get titleObjectDone(): TitleObject[] | [] {
    if (!this.titleObjects || !this.titleObjects.length) {
      return [];
    }
    return this.titleObjects.filter(
      (a: TitleObject) => a.state === TitleObjectStateEnum.Done
    );
  }

  public get titleObjectStarted(): TitleObject[] | [] {
    if (!this.titleObjects || !this.titleObjects.length) {
      return [];
    }
    return this.titleObjects.filter(
      (a: TitleObject) => a.state == TitleObjectStateEnum.Started
    );
  }

  public parseTime(value: number): string {
    if (value === 0 || !value) {
      return "0";
    } else {
      const timestamp = value;
      const hours = Math.floor(timestamp / 60 / 60);
      const minutes = Math.floor(timestamp / 60) - hours * 60;
      const seconds = timestamp % 60;

      return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
  }

  public removeTitleObject(id: number): void {
    this.$emit("removeTitleObject", id);
  }

  /** Просто обновляет статус на бэкенде в родителе и делает refetch евента **/
  public updateTitleStatus(
    item: TitleObject,
    status: TitleObjectStateEnum
  ): void {
    this.$emit("updateTitleStatus", { item: item, status: status });
  }
}
