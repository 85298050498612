


























































































































































































































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import TitleObjects from "@/components/widgets/events/TitleObjects.vue";
import StreamComponent from "@/components/widgets/events/Stream.vue";
import TitleObjectsTable from "@/components/widgets/events/TitleObjectsTable.vue";
import TitleObjectsOffline from "@/components/widgets/events/TitleObjectsOffline.vue";
import TitleObjectsTableOffline from "@/components/widgets/events/TitleObjectsTableOffline.vue";
import VideoService from "@/components/widgets/events/VideoService.vue";
import Chat from "@/components/widgets/events/Chat.vue";
import Statistics from "@/components/widgets/events/Statistics.vue";
import PartnersList from "@/components/widgets/events/PartnersList.vue";

import EventArchive from "@widgets/events/EventArchivate.vue";
import EventDeactivate from "@widgets/events/EventDeactivate.vue";
import EventDelete from "@widgets/events/EventDelete.vue";

import {
  createEvent,
  cycleEventByIdFromEvent,
  EventById,
  UpdateEvent,
  updateEventCycle,
  ParticipantsFromEvent,
} from "@/graphql/queries/Events.graphql";
import {
  AdditionalBlock,
  CreateEventInput,
  Event,
  Stream,
  TitleObject,
  UpdateEventInput,
  UpdateTitleObjectInput,
  DoctorSpecialty,
  PaymentTypeEnum,
  EventStatusEnum,
  UpdateEventCycleMutation,
  UpdateEventCycleMutationVariables,
  CreateEventMutation,
  CreateEventMutationVariables,
  TitleObjectStateEnum,
  AdditionalArchiveBlock,
  Maybe,
  User,
  SortOrder,
  QueryAllDoctorsSpecialtiesOrderByColumn,
} from "@/generated/graphql";
import { AllDoctorSpecialities } from "@/graphql/queries/DoctorsSpecialities.graphql";
import { Routes } from "@/types/core";
import { eventForms, eventStatuses, paymentTypes } from "@/core/static/dict";
import UpdatableView from "@/core/UI/Views/UpdatableView";
import MutationTransformer from "@/core/Transformers/GraphqlMutationFieldsTransformer";
import omitDeep from "omit-deep-lodash";
import Table from "@components/parts/tables/Table.vue";
import PickUsers from "@components/widgets/user/Picker.vue";
import FileInput from "@widgets/commons/Inputs/FileInput.vue";
import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";
import EventParticipants from "@widgets/events/EventParticipants.vue";
import DateInput from "@widgets/commons/Inputs/DateInput.vue";
import Points from "../../components/widgets/events/Points.vue";
import Map from "@widgets/commons/Map.vue";
import AdditionalBlocks from "@/components/widgets/events/AdditionalBlocks.vue";
import AdditionalArchiveBlocks from "@/components/widgets/events/AdditionalArchiveBlocks.vue";
import EventPersons from "@/components/widgets/events/EventPersons.vue";
import CityAutocomplete from "@/components/widgets/commons/Inputs/CityAutocomplete.vue";
import { mixins } from "vue-class-component/lib/util";
import userRolesMixin from "@/mixins/userRoles";
import { DateTime } from "luxon";
import DatePicker from "@/components/widgets/commons/Inputs/DatePicker.vue";
import { apolloClient } from "@/core/apollo";
import {
  deleteTitleObject,
  updateTitleObject,
} from "@/graphql/queries/TitleObject.graphql";
import EventProgram from "@widgets/events/EventProgram.vue";
import { getFormattedEventId } from "@/domain/event";

type TitleQueueType = Omit<
  UpdateTitleObjectInput,
  "stream" | "title" | "text" | "button_text" | "is_modal" | "pollAnswers"
>;

@Component({
  name: "EventDetail",
  components: {
    EventProgram,
    DatePicker,
    EventParticipants,
    EventArchivate: EventArchive,
    EventDeactivate,
    EventDelete,
    EventPersons,
    CityAutocomplete,
    TitleObjectsTable,
    PartnersList,
    FileInput,
    ImageInput,
    PickUsers,
    Table,
    Points,
    Map,
    TitleObjects,
    TitleObjectsOffline,
    TitleObjectsTableOffline,
    DateInput,
    AdditionalBlocks,
    AdditionalArchiveBlocks,
    StreamComponent,
    VideoService,
    Statistics,
    Chat,
  },
  apollo: {
    event: {
      query: EventById,
      variables() {
        return { id: this.$route.params.id };
      },
      skip() {
        return this.creationMode;
      },
      fetchPolicy: "no-cache",
    },
    participants: {
      query: ParticipantsFromEvent,
      variables() {
        return { id: this.$route.params.id };
      },
      skip() {
        return this.creationMode;
      },
      update(data) {
        const event = getFormattedEventId(data.event);
        return event.participants;
      },
      fetchPolicy: "no-cache",
    },
    allDoctorsSpecialties: {
      query: AllDoctorSpecialities,
      variables() {
        return {
          orderBy: [
            {
              column: QueryAllDoctorsSpecialtiesOrderByColumn.Name,
              order: SortOrder.Asc,
            },
          ],
        };
      },
    },
  },
})
export default class Events extends mixins(userRolesMixin, UpdatableView) {
  protected updateMutation = UpdateEvent;
  protected readonly cycleEventById = cycleEventByIdFromEvent;

  protected isArchive: boolean = false;
  protected isCycle: boolean = false;
  protected titleObjectsAdded: [TitleQueueType] | [] = [];
  protected removedTitles: [string] | [] = [];
  public stream: Stream | null = null;
  public titleObjects: Stream["titleObjects"] = [];
  private readonly eventForms = eventForms;
  protected readonly PaymentTypeEnum = PaymentTypeEnum;
  protected readonly EventStatusEnum = EventStatusEnum;
  private readonly paymentTypes = paymentTypes;
  private readonly eventStatuses = eventStatuses;
  protected readonly Routes: typeof Routes = Routes;
  private dataTransformer = new MutationTransformer();
  private event: Partial<Event> = {
    additionalBlocks: [],
  };
  private participants: Maybe<Array<User>> = [];
  protected file: File | string = "";

  protected titleObjectsLoading: boolean = false;
  protected titleObjectsRefetchError: boolean = false;
  protected titleObjectsRefetched: boolean = false;

  private allDoctorsSpecialties: DoctorSpecialty[] = [];

  private deleteAdditionalList: string[] = [];
  private deleteArchiveAdditionalList: string[] = [];
  private startSave = false;
  protected streamStarted: number = 0;

  protected get streamEnds(): boolean {
    const endDatetime = DateTime.fromISO(this.event.date_to).plus({ hour: 1 });
    const now = DateTime.now();
    return now > endDatetime;
  }

  /**
   * Собираем массив доп блоков на удаление
   */
  private setInDeleteArray(id: string): void {
    if (this.event.is_archive) this.deleteArchiveAdditionalList.push(id);
    this.deleteAdditionalList.push(id);
  }

  public created(): void {
    if (!this.VIEW_EVENT) this.$router.push({ name: Routes.noRights });
    this.isArchive = this.event.is_archive ?? false;
  }
  @Watch("event.stream")
  public onStream(val: Stream): void {
    this.stream = val;
    if (val as Stream) this.titleObjects = val.titleObjects;
  }

  @Watch("event.is_archive", { deep: true })
  public onArhieve(val: boolean): void {
    this.isArchive = val;
  }

  @Watch("event.date_from")
  public resetDateTo(): void {
    if (
      DateTime.fromISO(this.event.date_from).startOf("day") >
      DateTime.fromISO(this.event.date_to).startOf("day")
    ) {
      this.event.date_to = "";
    }
  }
  public $refs!: {
    observer: HTMLFormElement;
  };
  protected redirectBack(): void {
    const currentRoute = this.$router.currentRoute.name;
    setTimeout(() => {
      if (currentRoute === this.$router.currentRoute.name) {
        this.back();
      }
    }, 1000);
  }
  protected async validateAndSave(isExit: boolean): Promise<any> {
    const isValid = await this.$refs.observer.validate();

    if (!isValid) {
      this.$nextTick(() => {
        const el: any = this.$el.querySelector(
          ".v-messages.error--text:first-of-type"
        );
        this.$vuetify.goTo(el, { offset: 160 });
        return;
      });
    }

    try {
      this.startSave = true;
      if (isValid) {
        if (this.creationMode) {
          await this.createEvent(isExit);
        } else {
          await this.update();
          await this.updateEventData(isExit);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.startSave = false;
    }
  }

  get eventId(): string {
    if (this.event) {
      return this.event.id ? this.event.id : "";
    }
    return "";
  }

  protected async createEvent(isExit: boolean): Promise<void> {
    try {
      const result = await this.$apollo.mutate<
        CreateEventMutation,
        CreateEventMutationVariables
      >({
        mutation: createEvent,
        variables: { input: this.creationInput },
      });
      const parentCycleId = this.$route.query.parent;
      if (result?.data) {
        if (parentCycleId) {
          await this.$apollo.mutate<
            UpdateEventCycleMutation,
            UpdateEventCycleMutationVariables
          >({
            mutation: updateEventCycle,
            variables: {
              input: {
                id: "" + parentCycleId,
                events: { connect: [result.data.createEvent.id] },
              },
            },
          });
          await this.$router.replace({
            name: Routes.cycleEventsID,
            params: { id: "" + parentCycleId },
            hash: "#events",
          });
        }
        if (isExit) {
          this.redirectBack();
        } else {
          await this.$router.push(`/event/${result.data?.createEvent.id}`);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  protected get creationInput(): CreateEventInput {
    return (this.creationMode ? this._commonInput : {}) as CreateEventInput;
  }

  protected get updateInput(): UpdateEventInput {
    return (
      this.creationMode ? {} : { ...this._commonInput, id: this.event.id }
    ) as UpdateEventInput;
  }
  protected async addTitleObject(): Promise<void> {
    await this.updateEventData();
  }

  protected async updateTitleStatus(payload: {
    item: TitleObject;
    status: TitleObjectStateEnum;
  }): Promise<void> {
    try {
      this.titleObjectsLoading = true;
      await apolloClient.mutate({
        mutation: updateTitleObject,
        variables: {
          input: {
            id: payload.item.id,
            state: payload.status,
            start: payload.item.start,
          },
        },
      });
      if (payload.status === TitleObjectStateEnum.Started) {
        setTimeout(async () => {
          await this.updateEventData();
        }, payload.item.duration * 1000);
      }
      await this.updateEventData();
      this.titleObjectsRefetched = true;
    } catch (error) {
      console.error(error);
      this.titleObjectsRefetchError = true;
    } finally {
      this.titleObjectsLoading = false;
    }
  }

  protected async removeTitleObject(id: number): Promise<void> {
    try {
      this.titleObjectsLoading = true;
      await apolloClient.mutate({
        mutation: deleteTitleObject,
        variables: {
          id,
        },
      });
      await this.updateEventData();
    } catch (error) {
      console.error(error);
      this.titleObjectsRefetchError = true;
    } finally {
      this.titleObjectsLoading = false;
    }
  }
  protected async updateEventData(isExit = false): Promise<void> {
    try {
      await this.$apollo.queries.event.refetch();
      if (isExit) {
        this.redirectBack();
      }
    } catch (error) {
      this.isUpdateError = true;
      console.error(error);
    }
  }

  protected async RemoveTitle(id: string): Promise<void> {
    //@ts-ignore
    this.removedTitles.push(id);
  }

  protected get _commonInput(): UpdateEventInput & CreateEventInput {
    const event = this.event;
    const parentID = this.$route.params.parentID;

    const input = {
      public_name: event.public_name,
      private_name: event.private_name,
      price: event.price,
      event_status: event.event_status,
      payment_type: event.payment_type,
      event_form: event.event_form,
      is_registration_disabled: event.is_registration_disabled,
      description: event.description,
      geoposition: event.geoposition,
      date_from: event.date_from,
      date_to: event.date_to,
      min_duration: Number(event.min_duration),
      hide_producers: event.hide_producers || false,
      hide_anchorpersons: event.hide_anchorpersons || false,
      hide_managers: event.hide_managers || false,
      hide_lecturers: event.hide_lecturers || false,
    } as UpdateEventInput & CreateEventInput;

    if (event.amount_nmo) {
      input.amount_nmo = +event.amount_nmo;
    }

    if (event.partners) {
      input.partners = {
        sync: event.partners.map((partner) => ({
          id: partner.id,
          partner_status_id: partner.pivot?.partner_status_id as string,
        })),
      };
    }

    if (event.additionalBlocks) {
      const _clearFiles = (block: AdditionalBlock): AdditionalBlock => {
        const _block = { ...block };
        if (typeof _block.file === "string") delete _block.file;
        if (typeof _block.image === "string") delete _block.image;
        return _block;
      };
      const additional = event.additionalBlocks;

      const blocksForCreation = additional
        ?.filter((block) => !block.id && block.type)
        .map(_clearFiles);

      const blocksForUpdate = additional
        ?.filter((block) => block.id)
        .map(_clearFiles);

      input.additionalBlocks = {
        create: blocksForCreation,
        update: blocksForUpdate,
        delete: this.deleteAdditionalList || [],
      };
    }

    if (event.additionalArchiveBlocks && event.is_archive) {
      const _clearFiles = (
        block: AdditionalArchiveBlock
      ): AdditionalArchiveBlock => {
        const _block = { ...block };
        if (!block.files?.every((file) => file instanceof File))
          delete _block.files;
        if (!block.images?.every((image) => image instanceof File))
          delete _block.images;
        return _block;
      };
      const additional = event.additionalArchiveBlocks;

      const blocksForCreation = additional
        ?.filter((block) => !block.id && block.type)
        .map(_clearFiles);

      const blocksForUpdate = additional
        ?.filter((block) => block.id)
        .map(_clearFiles);
      input.additionalArchiveBlocks = {
        create: blocksForCreation,
        update: blocksForUpdate,
        delete: this.deleteArchiveAdditionalList || [],
      };
    }

    if (typeof event.city === "object") {
      input.city_id = omitDeep(event.city, "id");
    }

    if (
      (event.logotype_path as string | File) instanceof File ||
      event.logotype_path === null
    )
      input.logotype = event.logotype_path;
    if (
      (event.main_image_path as string | File) instanceof File ||
      event.main_image_path === null
    )
      input.main_image = event.main_image_path;
    if (
      (event.place_image_path as string | File) instanceof File ||
      event.place_image_path === null
    )
      input.place_image = event.place_image_path;

    if (event.specialties) {
      input.specialties = this.dataTransformer.transformArrayToSync(
        event.specialties
      );
    }
    if (!event.producers?.length) input.producers = { sync: [] };
    if (!event.lecturers?.length) input.lecturers = { sync: [] };
    if (!event.managers?.length) input.managers = { sync: [] };
    if (!this.participants?.length) input.participants = { sync: [] };
    if (!event.anchorpersons?.length) input.anchorpersons = { sync: [] };

    if (event.producers?.length)
      input.producers = {
        sync: event.producers.map((user) => ({
          id: user.id,
          person_status_id: user.producer_status?.person_status_id,
        })),
      };

    if (event.lecturers?.length)
      input.lecturers = {
        sync: event.lecturers.map((user) => ({
          id: user.id,
          person_status_id: user.lecturer_status?.person_status_id,
        })),
      };

    if (event.managers?.length)
      input.managers = {
        sync: event.managers.map((user) => ({
          id: user.id,
          person_status_id: user.manager_status?.person_status_id,
        })),
      };

    if (this.participants?.length)
      input.participants = {
        sync: this.participants.map((user) => ({
          id: user.id,
          participant_status: user.pivot?.participant_status,
        })),
      };

    if (event.anchorpersons?.length)
      input.anchorpersons = {
        sync: event.anchorpersons.map((user) => ({
          id: user.id,
          person_status_id: user.anchor_status?.person_status_id,
        })),
      };

    if (
      (event.activities as string | File) instanceof File ||
      event.activities === null
    ) {
      input.activities = event.activities;
    }

    if (event.event_status) input.event_status = event.event_status;
    if (parentID) input.eventCycles = { connect: [parentID] };
    return input;
  }
}
