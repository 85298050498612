
























































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  CheckNmoPointsQuery,
  CheckNmoPointsQueryVariables,
  CreateNmoPointMutationVariables,
  EventNmoPointsQuery,
  NmoPoints,
  NmoPointsInput,
  NmoStatusEnum,
  ParticipantStatusEnum,
  UpdateNmoMutation,
  UpdateNmoMutationVariables,
  User,
} from "@/generated/graphql";
import { Headers } from "@/types/table";
import { nmoStatuses, nmoStatusesNames } from "@/core/static/dict";
import PageCard from "@/components/page/Card.vue";
import { createNmoPoint } from "@/graphql/queries/CreateNmoPoint.graphql";
import {
  assignNmoPoints,
  checkNmoPoints,
} from "@/graphql/queries/NmoPoints.graphql";
import { eventNmoPoints, updateNMO } from "@/graphql/queries/Events.graphql";

@Component({
  components: {
    PageCard,
  },
  apollo: {
    event: {
      query: eventNmoPoints,
      fetchPolicy: "no-cache",
      variables() {
        return { event_id: this.eventId };
      },
      skip() {
        return !this.eventId;
      },
    },
  },
})
export default class Points extends Vue {
  @Prop({ type: String, default: null }) readonly eventId!: string;
  protected readonly eventNmoPointsQuery = eventNmoPoints;
  protected readonly creationMutation = createNmoPoint;
  protected readonly updateNMO = updateNMO;
  protected readonly assignNmoPointsMutation = assignNmoPoints;

  private nmoPoints: string[] = [];

  protected search: Record<string, string> = {};

  protected readonly NmoStatusEnum = NmoStatusEnum;
  protected readonly nmoStatusesNames = nmoStatusesNames;
  protected readonly headers: Headers = [
    { text: "Участник", value: "user" },
    { text: "Статус", value: "status" },
    { text: "Код", value: "code" },
    { text: "Баллы", value: "points" },
  ];
  protected readonly pointsPreviewHeaders: Headers = [
    { text: "Участник", value: "user_id" },
    { text: "Код", value: "code" },
    { text: "Баллы", value: "points" },
  ];

  protected event: EventNmoPointsQuery["event"] = null;
  protected nmoStatuses = nmoStatuses;

  protected step = 1;
  protected isDialogVisible: boolean = false;

  @Watch("isDialogVisible")
  protected async refetch(): Promise<void> {
    if (this.isDialogVisible) await this.$apollo.queries.event.refetch();
    else this.step = 1;
  }

  /**
   * Парсер файла
   * Парсит файл построчно в массив string[]
   */
  protected file: File | null = null;
  protected fileReading = false;
  private errorFileType = false;
  protected async proceedFile(): Promise<void> {
    if (!this.file) return;
    if (this.file.type !== "text/plain") {
      this.errorFileType = true;

      return;
    }
    await this.$apollo.queries.event.refetch({ event_id: this.eventId });
    const reader = new FileReader();
    this.fileReading = true;
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const points = e.target?.result
        ?.toString()
        .trim()
        .replace(/\r/g, "")
        .split("\n")
        .filter(Boolean);

      const {
        data: { checkNmo },
      } = await this.$apollo.query<
        CheckNmoPointsQuery,
        CheckNmoPointsQueryVariables
      >({
        query: checkNmoPoints,
        variables: {
          codes: Array.from(new Set(points)),
        },
      });
      if (checkNmo) {
        this.preparePoints(checkNmo);
        this.fileReading = false;
        this.step = 2;
      }
    };
    reader.readAsText(this.file);
  }

  /**
   * Формирование базовой структуры баллов НМО из массива строк
   */
  protected points: Array<{
    code: string;
    points: number;
    user_id?: User["id"];
  }> = [];
  protected preparePoints(points: string[]): void {
    this.points = points.map((code) => {
      return {
        code,
        points: this.event?.amount_nmo || 0,
      };
    });
  }

  /** Методы автоматизации */

  /**
   * Отзывает пользователей у баллов
   */
  protected automaticRevokeUsersFromAll(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.points = this.points.map(({ user_id, ...nmo }) => nmo);
  }

  /**
   * Назначает всем баллам участников
   */
  protected automaticAssignUsersToAll(): void {
    this.points = this.points.map((nmo, index) => {
      const user = this.freeUsers?.[index];
      return user ? { ...nmo, user_id: user.id } : nmo;
    });
  }

  protected toggleStatusNMOLoading: string[] = [];
  protected toggleStatusNMOError: any = false;
  protected async toggleNMOStatus({ id, status }: NmoPoints): Promise<void> {
    try {
      this.toggleStatusNMOLoading.push(id);
      await this.$apollo.mutate<UpdateNmoMutation, UpdateNmoMutationVariables>({
        mutation: updateNMO,
        variables: {
          id,
          status:
            status === NmoStatusEnum.Credited
              ? NmoStatusEnum.Reserve
              : NmoStatusEnum.Credited,
          user: {
            disconnect: true,
          },
        },
      });
      await this.$apollo.queries.event.refetch();
    } catch (e) {
      console.error(e);
      this.toggleStatusNMOError = e;
    } finally {
      this.toggleStatusNMOLoading.splice(
        this.toggleStatusNMOLoading.indexOf(id),
        1
      );
      this.toggleStatusNMOError = false;
    }
  }

  protected get pointsInput():
    | CreateNmoPointMutationVariables["input"]
    | undefined {
    const eventId = this.event?.id;
    if (eventId) {
      return this.points.map((nmo) => {
        const input: NmoPointsInput = {
          code: nmo.code,
          points: +nmo.points,
          event_id: eventId,
          status: nmo.user_id ? NmoStatusEnum.Credited : NmoStatusEnum.Reserve,
          user_id: nmo.user_id || null,
        };

        return input;
      });
    }
    return undefined;
  }

  /**
   * Пользователи без назначенных баллов, Свободные пользователи
   */
  protected get freeUsers(): NonNullable<
    EventNmoPointsQuery["event"]
  >["participants"] {
    return (
      this.event?.participants?.filter((user) => {
        const point = this.event?.nmoPoints?.find(
          (p) => p.user?.id === user?.id
        );
        const isUserPassedEvent =
          user?.pivot?.participant_status === ParticipantStatusEnum.Passed ||
          false;
        const selected = this.points.find(({ user_id }) => user.id === user_id);
        return !point && !selected && isUserPassedEvent;
      }) || []
    );
  }

  protected freeUsersWithSelected(
    id: User["id"]
  ): NonNullable<EventNmoPointsQuery["event"]>["participants"] {
    const selectUser = this.event?.participants?.find(
      ({ id: idUser }) => idUser === id
    );
    if (selectUser) {
      return this.freeUsers?.concat([selectUser]);
    }
    return this.freeUsers;
  }

  /**
   * True если есть резервные баллы для назначения
   */
  protected get isReservedPointsAvailable(): boolean {
    return !this.event?.nmoPoints
      ? false
      : this.event.nmoPoints.filter(
          (nmo) => nmo.status === NmoStatusEnum.Reserve
        )?.length > 0 || false;
  }
}
