





















import { Vue, Component, VModel, Emit } from "vue-property-decorator";
import { UsersList } from "@/graphql/queries/User.graphql";
import {
  User,
  QueryUsersWhereWhereConditions,
  InputUsersEnum,
  SqlOperator,
} from "@/generated/graphql";
//@ts-ignore
import VueInfiniteAutocomplete from "vue-infinite-autocomplete";
type selectType = {
  id: string;
  text: string;
};
import userSearch from "@/mixins/userSearch";

@Component({
  name: "UsersPicker",
  components: {
    "vue-infinite-autocomplete": VueInfiniteAutocomplete,
  },
  mixins: [userSearch],
})
export default class UsersPicker extends Vue {
  @VModel({ type: Boolean, default: false }) visible!: boolean;
  private users: selectType[] = [];

  @Emit("select")
  private selectUser(selected: User): User | unknown {
    //@ts-ignore
    const user = this.fullUsers.find((user: User) => selected.id === user.id);
    return user;
  }
}
