













import { Vue, Component, Prop } from "vue-property-decorator";
import { Stream } from "@/generated/graphql";

@Component({
  name: "VideoService",
})
export default class extends Vue {
  @Prop() stream!: Stream;
  googleSpreadsheetUrl: string = "";
  iframe_code: string = "";

  mounted() {
    if (!this.stream) return;
    this.googleSpreadsheetUrl = this.stream.google_spreadsheet_url;
    this.iframe_code = this.stream.iframe_code;
  }
}
