import {
  InputUsersEnum,
  SqlOperator,
  User,
  QueryUsersWhereWhereConditions,
} from "@/generated/graphql";

type selectType = {
  id: string;
  text: string;
};
import { UsersList } from "@/graphql/queries/User.graphql";

export default {
  data() {
    return {
      users: [],
      searchProcess: false,
      searchValue: "",
      limit: 10,
      fullUsers: [],
    };
  },
  methods: {
    clearData(users: User[]) {
      if (!users) return [];
      return users.map((previousValue: User): selectType => {
        return {
          id: previousValue?.id,
          text: `${previousValue?.first_name} ${previousValue?.second_name} ${previousValue?.family_name}`,
        };
      });
    },
    async getAsyncOptions(
      text: string,
      page: number,
      fetchSize: number
    ): Promise<selectType[]> {
      let variables: {
        page: number;
        first: number;
        where?: QueryUsersWhereWhereConditions;
      } = {
        page,
        first: fetchSize,
      };
      if (text) {
        const parts = text.split(" ");
        const firstName = parts[0];
        const familyName = parts[1] ? parts[1] : null;
        const secondName = parts[2] ? parts[2] : null;
        const OR = [];
        if (parts.length > 2) {
          OR.push({
            column: InputUsersEnum["FirstName"],
            operator: SqlOperator["Ilike"],
            value: `%${firstName}%`,
          });
        }
        if (parts.length == 1) {
          OR.push({
            column: InputUsersEnum["FirstName"],
            operator: SqlOperator["Ilike"],
            value: `%${firstName}%`,
          });
          OR.push({
            column: InputUsersEnum["FamilyName"],
            operator: SqlOperator["Ilike"],
            value: `%${firstName}%`,
          });
          OR.push({
            column: InputUsersEnum["SecondName"],
            operator: SqlOperator["Ilike"],
            value: `%${firstName}%`,
          });
        }
        if (familyName) {
          OR.push({
            column: InputUsersEnum["FamilyName"],
            operator: SqlOperator["Ilike"],
            value: `%${familyName}%`,
          });
        }

        if (secondName) {
          OR.push({
            column: InputUsersEnum["SecondName"],
            operator: SqlOperator["Ilike"],
            value: `%${secondName}%`,
          });
        }

        variables = {
          ...variables,
          where: {
            OR,
          },
        };
      }
      //@ts-ignore
      const result = await this.$apollo.query({
        query: UsersList,
        variables,
      });
      //@ts-ignore
      this.fullUsers = [...this.fullUsers, ...result.data.users.data];
      return this.clearData(result.data.users.data);
    },
  },
};
