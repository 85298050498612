































































import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteEvent } from "@/graphql/queries/Events.graphql";
import { Event, Stream } from "@/generated/graphql";
import gql from "graphql-tag";

@Component({
  apollo: {
    event: {
      query: gql`
        query ($id: ID!) {
          event(id: $id) {
            id
            stream {
              is_started
            }
          }
        }
      `,
      variables(this: EventDelete): { id: string } {
        return { id: this.eventId };
      },
    },
  },
})
export default class EventDelete extends Vue {
  @Prop({ type: String, default: "" }) eventId!: string;
  @Prop({ type: Boolean, default: false }) isArchive!: string;

  public event!: Pick<Event, "id" | "is_active"> & {
    stream: Pick<Stream, "id" | "is_started">;
  };

  protected get isStreamStarted(): boolean {
    return !!this.event?.stream?.is_started;
  }

  protected visible = false;
  protected readonly deleteMutation = deleteEvent;
}
