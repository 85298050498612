import { DocumentNode } from "graphql";
import Component from "vue-class-component";
import UIError from "@/core/Exceptions/UIError";
import MutatableView from "@/core/UI/Views/MutatableView";
import { Routes } from "@/types/core";
import { FetchResult } from "apollo-link";

export enum FieldTypesEnum {
  string = "string",
  array = "array",
  number = "number",
  boolean = "boolean",
}

@Component({ name: "updatable_view" })
export default class UpdatableView extends MutatableView {
  protected isUpdateProcess: boolean = false;
  protected isUpdated: boolean = false;
  protected isUpdateError: boolean = false;
  protected isUpdatedText: string = "Данные обновлены";
  protected isUpdatedErrorText: string = "Данные обновлены";

  protected readonly updateMutation!: DocumentNode;
  protected readonly createMutation!: DocumentNode;
  protected readonly deleteMutation!: DocumentNode;
  protected isArchive!: boolean;
  protected isCycle!: boolean;

  protected get _getUpdateMutation(): DocumentNode {
    if (!this.updateMutation)
      throw new UIError(
        "[ UpdatableView::get input() ] subclass has no provide updateMutation"
      );
    return this.updateMutation;
  }

  protected get _getDeleteMutation(): DocumentNode {
    if (!this.deleteMutation)
      throw new UIError(
        "[ UpdatableView::get input() ] subclass has no provide deleteMutation"
      );
    return this.deleteMutation;
  }

  protected get _getCreateMutation(): DocumentNode {
    if (!this.createMutation)
      throw new UIError(
        "[ UpdatableView::get input() ] subclass has no provide createMutation"
      );
    return this.createMutation;
  }
  public back(): void {
    const link = this.isArchive
      ? Routes["admin/archive"]
      : this.isCycle
      ? Routes["cycleEvents"]
      : Routes["events"];
    this.$router.push({ name: link });
  }

  protected async update<T = Record<string, unknown>>(): Promise<
    FetchResult<T> | undefined
  > {
    const mutation = this.creationMode
      ? this._getCreateMutation
      : this._getUpdateMutation;
    try {
      const input = this.creationMode ? this.creationInput : this.updateInput;
      this.isUpdateProcess = true;
      const data = await this.$apollo.mutate<T>({
        mutation: mutation,
        variables: { input },
      });
      this.isUpdatedText = "Данные обновлены";
      this.isUpdateProcess = false;
      this.isUpdated = true;
      return data;
    } catch (e: any) {
      console.error("[ UPDATABLE VIEW::UPDATE ERROR ]", e);
      this.isUpdateError = true;
      this.isUpdatedErrorText = e;
    } finally {
      this.isUpdateProcess = false;
    }
  }
}
