


























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  createStream,
  updateStream,
  startStream,
} from "@/graphql/queries/Stream.graphql";
import { CreateStreamInput, Stream } from "@/generated/graphql";
import { apolloClient } from "@/core/apollo";
import DateInput from "@widgets/commons/Inputs/DateInput.vue";
import { DateTime } from "luxon";

@Component({
  name: "Stream",
  components: {
    DateInput,
  },
})
export default class extends Vue {
  @Prop({ type: String, required: true, default: NaN }) eventID!: string;
  @Prop({ type: Boolean, default: false }) disableLectorButton!: boolean;
  @Prop({ required: false, default: null }) stream!: Stream;
  date: string = "";
  time: string = "";
  done: boolean = false;
  id: string | null = null;
  disableStart: boolean = false;
  disableStop: boolean = false;
  disableComplete: boolean = false;

  input: Omit<CreateStreamInput, "event_id"> = {
    start: this.stream?.start || Date.now().toString(),
    stream_key: this.stream?.stream_key || "",
    iframe_code: this.stream?.iframe_code || "",
    google_spreadsheet_url: this.stream?.google_spreadsheet_url || "",
  };

  valid: boolean = false;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  required: [(v: any) => string | boolean] = [(v) => !!v || "Поле обязательно"];
  mounted() {
    const stream = this.stream;
    if (!stream) return;
    this.disableStart = this.stream.is_started || this.stream.is_completed;
    this.disableStop = !this.stream.is_started;
    this.disableComplete = !this.stream.is_started;
    this.input = {
      start: this.stream?.start || Date.now().toString(),
      stream_key: this.stream?.stream_key || "",
      iframe_code: this.stream?.iframe_code || "",
      google_spreadsheet_url: this.stream?.google_spreadsheet_url || "",
    };
    if (this.stream?.id) {
      this.id = stream.id;
      //@ts-ignore;
      this.input.id = stream.id;
    }
    const date = DateTime.fromISO(this.stream?.start);
    this.date = date.toISO();
    this.time = date.toFormat("HH:mm");
  }
  @Watch("date")
  onDateChange(): void {
    this.input.start = DateTime.fromISO(this.date)
      .startOf("day")
      .toISO({ suppressMilliseconds: true });
  }
  @Watch("time")
  onTimeChange(): void {
    this.setStart();
  }
  get EDIT_STREAM(): boolean {
    return this.$store.getters["session/isAllow"]("EDIT_STREAM");
  }
  get CREATE_STREAM(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_STREAM");
  }
  setStart(): void {
    const hour = parseInt(DateTime.fromISO(this.time).toFormat("HH"));
    const minute = parseInt(DateTime.fromISO(this.time).toFormat("mm"));
    this.input.start = DateTime.fromISO(this.date)
      .startOf("day")
      .set({
        hour,
        minute,
      })
      .toISO({ suppressMilliseconds: true });
  }
  async streamStarted(id: string | null): Promise<void> {
    if (id === null) return;
    try {
      await apolloClient.mutate({
        mutation: startStream,
        variables: { id },
      });
    } catch (err: unknown) {
      console.error(err);
    }
  }
  async run(): Promise<void> {
    if (!this.EDIT_STREAM) {
      alert("Нет прав на редактирование стрима");
      return;
    }
    this.streamStarted(this.id);
    this.$emit("startStream");
    this.disableStop = false;
    this.disableComplete = false;
    this.disableStart = true;
  }
  async stop(): Promise<void> {
    if (!this.EDIT_STREAM) {
      alert("Нет прав на редактирование стрима");
      return;
    }
    const id = this.id;
    if (id === null) return;
    try {
      await apolloClient.mutate({
        mutation: updateStream,
        variables: { input: { id, is_started: false } },
      });
      this.disableStop = true;
      this.disableStart = false;
      this.$emit("stopStream");
    } catch (err: unknown) {
      console.error(err);
    }
  }
  async complete(): Promise<void> {
    if (!this.EDIT_STREAM) {
      alert("Нет прав на редактирование стрима");
      return;
    }
    const id = this.id;
    if (id === null) return;
    try {
      await apolloClient.mutate({
        mutation: updateStream,
        variables: { input: { id, is_completed: true } },
      });
      this.disableStop = true;
      this.disableStart = true;
      this.disableComplete = true;
      this.$emit("stopStream");
    } catch (err: unknown) {
      console.error(err);
    }
  }
  async save(): Promise<void> {
    const event_id = this.eventID
      ? +this.eventID
      : this.stream
      ? +this.stream.event.id
      : undefined;

    let input = {
      ...this.input,
    };
    if (typeof event_id === "number") {
      input = { ...input, event_id } as CreateStreamInput;
    }
    let mutation = createStream;
    if (this.id !== null || typeof this.stream?.id !== "undefined") {
      const id = this.stream?.id ? this.stream?.id : this.id;
      if (id) {
        input = { ...input, ...{ id } };
        mutation = updateStream;
      }
      if (!this.EDIT_STREAM) {
        alert("Нет прав на редактирование стрима");
        return;
      }
    } else {
      if (!this.CREATE_STREAM) {
        alert("Нет прав на создание стрима");
        return;
      }
    }
    try {
      const { data } = await apolloClient.mutate({
        mutation: mutation,
        variables: { input },
      });
      if (data && data.createStream) {
        this.id = data.createStream.id;
        this.$emit("StreamCreated", data.createStream.id);
      }
      this.done = true;
      this.disableStop = true;
      this.$emit("updateEventData");
    } catch (err: unknown) {
      console.error(err);
    }
  }
}
