var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({attrs:{"mutation":_vm.updateMutation},on:{"done":function($event){_vm.visible = false;
    _vm.$emit('done');
    _vm.$apollo.queries.event.refresh();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
    var loading = ref.loading;
return [_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"title":_vm.$apollo.queries.event.loading &&
          'Идет проверка возможности деактивации',"color":"red","text":"","disabled":_vm.$apollo.queries.event.loading},on:{"click":function($event){return _vm.$apollo.queries.event.refresh()}}},on),[_vm._v(" "+_vm._s(_vm.isActive ? "Деактивировать" : "Активировать")+" ")])]}}],null,true),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Управление деактивацией")]),(_vm.$apollo.queries.event.loading)?_c('div',{staticClass:"d-flex justify-center py-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1):(_vm.isStreamStarted)?[_c('v-card-text',[_c('v-alert',{attrs:{"color":"warning","text":""}},[_vm._v("Нельзя деактивировать мероприятие пока запущена трансляция")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v("отмена")])],1)]:_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","loading":loading},on:{"click":function($event){return mutate({
              variables: {
                input: { id: _vm.eventId, is_active: !_vm.isActive },
              },
            })}}},[_vm._v(" "+_vm._s(_vm.isActive ? "Деактивировать" : "Активировать")+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v("отмена")])],1)],2)],1)]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }