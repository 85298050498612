var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4 py-4"},[_c('v-card-title',[_vm._v("Очередь вывода")]),(_vm.titleObjectsLoading)?_c('v-overlay',{attrs:{"absolute":true,"opacity":"0.10","z-index":"4"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1):_vm._e(),_c('div',{staticClass:"mx-4"},[[_c('v-card-title',[_vm._v("Титровальные объекты в очереди")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","disabled":_vm.titleObjectsLoading,"hide-default-footer":"","headers":_vm.headers,"items":_vm.titleObjectQueued},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TitleObjectState[item.state])+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.start))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.duration))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TitleObjectType[item.type])+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.EDIT_TITLE_OBJECT)?_c('v-btn',{attrs:{"disabled":_vm.titleObjectsLoading},on:{"click":function($event){return _vm.run(item)}}},[_vm._v(" Запустить")]):_vm._e(),(_vm.DELETE_TITLE_OBJECT)?_c('v-btn',{staticClass:"ml-2 error",attrs:{"disabled":_vm.titleObjectsLoading},on:{"click":function($event){return _vm.removeTitleObject(item.id)}}},[_vm._v("Удалить")]):_vm._e()]}}],null,true)})],[_c('v-card-title',{staticClass:"mt-4"},[_vm._v("Запущенные титровальные объекты")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.titleObjectStarted,"disabled":_vm.titleObjectsLoading},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TitleObjectState[item.state])+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.start))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.duration))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TitleObjectType[item.type])+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":""}},[_vm._v("Запустить")]),_c('v-btn',{staticClass:"ml-2 error",attrs:{"disabled":_vm.titleObjectsLoading},on:{"click":function($event){return _vm.stop(item)}}},[_vm._v("Снять")])]}}],null,true)}),_c('v-divider')],[_c('v-card-title',[_vm._v("Выполненные титровальные объекты")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.titleObjectDone,"disabled":_vm.titleObjectsLoading},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TitleObjectState[item.state])+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.start))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.duration))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TitleObjectType[item.type])+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":""}},[_vm._v("Запустить")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":""}},[_vm._v("Снять")]),_c('v-btn',{staticClass:"ml-2 error",attrs:{"disabled":_vm.titleObjectsLoading},on:{"click":function($event){return _vm.removeTitleObject(item.id)}}},[_vm._v("Удалить")])]}}],null,true)}),_c('v-divider')]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }