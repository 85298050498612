































































































































































































































import { Component, Prop } from "vue-property-decorator";
import {
  ChatMessages,
  SendMessage,
  deleteMessage,
} from "@/graphql/queries/Events.graphql";
import * as rules from "@/core/validation";
import {
  SqlOperator,
  ChatMessage,
  User,
  QueryChatMessagesWhereColumn,
  ChatMessagesQueryVariables,
  SendMessageMutation,
  SendMessageMutationVariables,
  DeleteMessageMutation,
  DeleteMessageMutationVariables,
} from "@/generated/graphql";
import { echoClient } from "@/core/apollo";
import userRolesMixin from "@/mixins/userRoles";
import { mixins } from "vue-class-component";
import { SocketIoPrivateChannel } from "laravel-echo/dist/channel";

type ChatMessageRequired = Required<ChatMessage> & { user: User };

type DeleteMessageResponse = {
  event_id: number;
  id: number;
};

@Component({
  name: "Chat",
  apollo: {
    chatMessages: {
      query: ChatMessages,
      variables(): ChatMessagesQueryVariables {
        return {
          where: {
            AND: [
              {
                column: QueryChatMessagesWhereColumn.EventId,
                operator: SqlOperator.Eq,
                value: this.eventId,
              },
            ],
          },
        };
      },

      /**
       * @see {@link https://apollo.vuejs.org/guide/apollo/queries.html#name-matching}
       * @param data
       */
      update: (data: { chatMessages?: ChatMessage[] }) => {
        return data?.chatMessages || [];
      },

      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    },
  },
})
export default class extends mixins(userRolesMixin) {
  @Prop({ type: String, required: true })
  public eventId!: string;

  /**
   * Validation rules
   */
  readonly rules = rules;

  private created() {
    this.listenChat();
  }

  private updated(): void {
    let messageDisplay = this.$refs.messageDisplay;
    if (messageDisplay instanceof HTMLElement)
      messageDisplay.scrollTop = messageDisplay?.scrollHeight;
  }

  private beforeDestroy() {
    this.leaveChat();
  }

  /**
   * Error as string
   * @private
   */
  private error: null | string = null;

  /**
   * @param error
   * @private
   */
  private handleError(error: any) {
    // reserved for log or any report function
    this.showError(error);
  }

  /**
   * Parse error to string
   * @param error
   * @private
   */
  private showError(error: any) {
    let errorString;
    try {
      errorString = error.toString();
    } catch (e) {
      errorString = "";
    }

    this.error = errorString;

    setTimeout(() => {
      this.error = null;
    }, 5000);
  }

  /**
   * @private
   */
  private get isErrorCaptured() {
    return !!this.error;
  }

  /**
   * Opens websocket channel and watch updates
   * @private
   */
  private listenChat() {
    const channel = echoClient.private(
      `chat.${this.eventId}`
    ) as SocketIoPrivateChannel;

    channel.eventFormatter.format = (name: string): string =>
      ["App", "Events", name].join("\\");

    channel.listen("AddChatMessageEvent", this.pushMessage);
    channel.listen("DeleteChatMessageEvent", this.popMessage);
    channel.listen("DeleteAllChatMessagesEvent", this.flushMessages);
  }

  private leaveChat() {
    echoClient.leave(`chat.${this.eventId}`);
  }

  /**
   * Push new message into chatMessage
   * @param message
   * @private
   */
  private pushMessage(message: ChatMessageRequired) {
    this.chatMessages.push(message);
  }

  /**
   * Pop removed message
   * @param id
   * @private
   */
  private popMessage({ id }: DeleteMessageResponse) {
    const chatMessageIndex = this.chatMessages.findIndex((message) =>
      message.id ? +message.id === id : false
    );

    if (this.chatMessages.at(chatMessageIndex))
      this.chatMessages.splice(chatMessageIndex, 1);
  }

  /**
   * Clear chat
   * @private
   */
  private flushMessages() {
    this.chatMessages = [];
  }

  /**
   * Global apollo loading state
   */
  public get isLoading() {
    return this.$apollo.loading;
  }

  /**
   * Retry chatMessage query
   */
  public reFetch() {
    this.$apollo.queries.chatMessages.refresh();
  }

  /**
   * Explicit declare $refs members type
   */
  public $refs!: {
    sendForm: HTMLFormElement;
    messageDisplay: HTMLElement;
  };

  /**
   * @protected
   */
  protected isMessageSending = false;

  /**
   * Current message input
   */
  public message = "";

  /**
   * Chat messages store
   */
  public chatMessages: ChatMessageRequired[] = [];

  /**
   * User data for reply
   * @protected
   */
  protected replyUser: ChatMessageRequired["user"] | null = null;

  /**
   * Set reply user before sending message
   * @param user User object for reply
   */
  protected setReplyUser(user: ChatMessageRequired["user"]) {
    this.replyUser = user;
  }

  /**
   * @protected
   */
  protected get getAuthorizedUser() {
    return this.$store.getters["session/me"];
  }

  protected get getAuthorizedUserFullName() {
    return this.$store.getters["session/fullName"];
  }

  /**
   * @param message Message sender
   * @protected
   */
  protected checkIsOwnMessage(message: ChatMessageRequired) {
    return +message.user?.id === +this.getAuthorizedUser.id;
  }

  /**
   * Parse and trim user full name
   * @param user
   * @protected
   */
  protected parseUserName(user: ChatMessageRequired["user"]) {
    return this.$store.getters["parseUserFullName"](user);
  }

  /**
   * Send message
   */
  protected async sendMessage() {
    try {
      this.isMessageSending = true;
      await this.$apollo.mutate<
        SendMessageMutation,
        SendMessageMutationVariables
      >({
        mutation: SendMessage,
        variables: {
          input: {
            message: this.message,
            ...(this.replyUser?.id ? { reply_to: this.replyUser?.id } : {}),
            event_id: this.eventId,
          },
        },
      });
    } catch (e) {
      this.handleError(e);
    } finally {
      this.message = "";
      this.isMessageSending = false;
      this.$nextTick(() => {
        this.$refs.sendForm.focus();
      });
    }
  }

  /**
   * Deletion confirm dialog
   * @protected
   */
  protected showDeletionDialog = false;

  /**
   *
   * @param messageId
   * @param options
   * @protected
   */
  protected async deleteMessage(
    { messageId, all }: { messageId?: string; all: boolean } = { all: false }
  ) {
    if (all) this.showDeletionDialog = true;

    try {
      await this.$apollo.mutate<
        DeleteMessageMutation,
        DeleteMessageMutationVariables
      >({
        mutation: deleteMessage,
        variables: {
          message_id: messageId,
          all: all || false,
          event_id: this.eventId,
        },
      });
    } catch (e) {
      this.handleError(e);
    } finally {
      this.showDeletionDialog = false;
    }
  }
}
