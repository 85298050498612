
































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "FormPrompt",
})
export default class EditPrompt extends Vue {
  @Prop({ type: Boolean, default: false }) disabled: boolean = false;
  protected isValid: boolean = false;
  private visible: boolean = false;

  @Emit()
  private open(): boolean {
    return (this.visible = true);
  }

  private decline(): void {
    this.visible = false;
  }

  @Emit("submit")
  private submit(): void {
    this.visible = false;
  }
}
