







































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TitleObject, TitleObjectStateEnum, Stream } from "@/generated/graphql";
import { DataTableHeader } from "vuetify";
import { TitleObjectType, TitleObjectState } from "@/core/static/dict";
import { mixins } from "vue-class-component";
import userRolesMixin from "@/mixins/userRoles";

@Component({
  name: "TitleObjectsTableOffline",
  computed: {
    titleObjectStateEnums() {
      return TitleObjectStateEnum;
    },
  },
})
export default class extends mixins(userRolesMixin, Vue) {
  @Prop({ default: [] }) titleObjects!: TitleObject[];
  @Prop({ required: true }) stream!: Stream;
  @Prop({ required: true, type: Number }) streamStarted!: number;
  @Prop({ type: Boolean, default: true }) titleObjectsLoading!: boolean;

  headers: Array<DataTableHeader> = [
    { text: "Состояние", value: "state" },
    { text: "Длительность", value: "duration" },
    { text: "Блок", value: "type" },
    { text: "Действие", value: "action" },
  ];
  TitleObjectState = TitleObjectState;
  TitleObjectType = TitleObjectType;
  enteredPage: boolean = true;
  timerStop: Record<string, ReturnType<typeof setTimeout>> = {};
  timerStart: Record<string, ReturnType<typeof setTimeout>> = {};

  beforeDestroy(): void {
    this.clearTimers();
  }

  clearTimers(): void {
    for (let key in this.timerStart) {
      clearTimeout(this.timerStart[key]);
    }
    for (let key in this.timerStop) {
      clearTimeout(this.timerStop[key]);
    }
    this.timerStop = {};
    this.timerStart = {};
  }
  private secondsTomiliseconds(duration: number) {
    return duration * 1000;
  }

  private async run(item: TitleObject): Promise<void> {
    clearTimeout(this.timerStart[item.id]);
    clearTimeout(this.timerStop[item.id]);
    this.$emit("updateTitleStatus", {
      item,
      status: TitleObjectStateEnum.Started,
    });
    this.timerStop[item.id] = setTimeout(() => {
      this.$emit("updateTitleStatus", {
        item,
        status: TitleObjectStateEnum.Done,
      });
    }, item.duration * 1000);
  }
  public stop(item: TitleObject): void {
    clearTimeout(this.timerStop[item.id]);
    this.updateTitleStatus(item, TitleObjectStateEnum.Done);
  }
  public get titleObjectQueued(): TitleObject[] | [] {
    if (!this.titleObjects || !this.titleObjects.length) {
      return [];
    }
    return this.titleObjects.filter(
      (a: TitleObject) => a.state === TitleObjectStateEnum.Queued
    );
  }

  public get titleObjectDone(): TitleObject[] | [] {
    if (!this.titleObjects || !this.titleObjects.length) {
      return [];
    }
    return this.titleObjects.filter(
      (a: TitleObject) => a.state === TitleObjectStateEnum.Done
    );
  }

  public get titleObjectStarted(): TitleObject[] | [] {
    if (!this.titleObjects || !this.titleObjects.length) {
      return [];
    }
    return this.titleObjects.filter(
      (a: TitleObject) => a.state == TitleObjectStateEnum.Started
    );
  }

  public parseTime(value: number): string {
    if (value === 0 || !value) {
      return "0";
    } else {
      const timestamp = value;
      const hours = Math.floor(timestamp / 60 / 60);
      const minutes = Math.floor(timestamp / 60) - hours * 60;
      const seconds = timestamp % 60;

      return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
  }

  public removeTitleObject(id: number): void {
    this.$emit("removeTitleObject", id);
  }

  /** Просто обновляет статус на бэкенде в родителе и делает refetch евента **/
  public updateTitleStatus(
    item: TitleObject,
    status: TitleObjectStateEnum
  ): void {
    this.$emit("updateTitleStatus", { item: item, status: status });
  }
}
