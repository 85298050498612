












import Vue from "vue";
import { Component, VModel } from "vue-property-decorator";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { required } from "@/core/validation";

@Component({
  name: "TimeInput",
  components: { VueTimepicker },
  methods: {
    required,
  },
})
export default class TimeInput extends Vue {
  @VModel({ required: true, default: 0 }) timeInput!: number;

  public timeInputMenu: boolean = false;

  public get timeInputValue(): { HH: string; mm: string; ss: string } {
    if (this.timeInput === 0) {
      return {
        HH: "00",
        mm: "00",
        ss: "00",
      };
    } else {
      const timestamp = this.timeInput;
      const hours = Math.floor(timestamp / 60 / 60);
      const minutes = Math.floor(timestamp / 60) - hours * 60;
      const seconds = timestamp % 60;

      return {
        HH: hours < 10 ? `0${hours}` : hours.toString(),
        mm: minutes < 10 ? `0${minutes}` : minutes.toString(),
        ss: seconds < 10 ? `0${seconds}` : seconds.toString(),
      };
    }
  }

  public set timeInputValue(value: { HH: string; mm: string; ss: string }) {
    if (!value) {
      this.timeInput = 0;
    } else {
      const { HH, mm, ss } = value;

      this.timeInput =
        Math.round(Number(HH) * 60 * 60) + Number(mm) * 60 + Number(ss);
    }
  }
}
