import { BaseView } from "@/core/UI/Views/BaseView";
import { Component } from "vue-property-decorator";

type _Record = Record<string, unknown>;

@Component
// TODO: remove generics
export default class MutatableView<
  UpdateInput extends _Record = _Record,
  CreationInput extends _Record = _Record
> extends BaseView {
  protected get creationMode(): boolean {
    return !this.$route.params.id;
  }

  protected get creationInput(): CreationInput | void {
    return;
  }

  protected get updateInput(): UpdateInput | void {
    return this.input;
  }

  /*
    @deprecated Use updateInput instead
    TODO: remove input()
   */
  protected get input(): UpdateInput | void {
    return;
  }
}
